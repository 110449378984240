@import '../../../../scss/theme-bootstrap';

.product__inventory-status {
  color: $color-red;
  display: inline-block;
  height: 37px;
  margin: 5px 10px 0;
  vertical-align: top;
  @include breakpoint($landscape-up) {
    margin: 0;
    max-width: 270px;
  }
  &-item {
    @include vertical-center();
  }
  .spp-add-bag-bar & {
    @include breakpoint($landscape-up) {
      margin: 5px 10px;
    }
  }
}

.preorder-container {
  .pre_order {
    &_active_msg,
    &_sold_msg,
    &_out_stock_msg {
      text-transform: uppercase;
      color: $color-black;
    }
  }
  .product-full__details & {
    margin-top: 3px;
    display: block;
    @include breakpoint($portrait-up) {
      margin-top: 10px;
    }
  }
}

// width changed for CTA at TOS/Soldout for iPhone5
.pre-order-sold-mbl {
  &.spp-add-bag-bar {
    .spp-add-bag-bar__details {
      width: 55%;
      @include breakpoint($portrait-up) {
        width: auto;
      }
    }
    .button {
      width: 43%;
      @include breakpoint($portrait-up) {
        width: auto;
      }
    }
  }
}
